import React from "react";
import {
  H1,
  H2,
  H3,
  Body1,
  Body2,
  Body3,
  Body1Bold,
  Body2Bold,
  CommonContainer,
} from "./TypographyElements";

export default ({
  h1,
  h2,
  h3,
  body1,
  body2,
  body3,
  body1Bold,
  body2Bold,
  commonContainer,
  color,
  fontSize,
  children,
  marginTop,
  height,
  minHeight,
}) => {
  const style = {
    marginTop: marginTop && marginTop,
    height: height && height,
    minHeight: minHeight && minHeight,
    overflow: "hidden",
  };
  if (h1) {
    return <H1 color={color}>{children}</H1>;
  }
  if (h2) {
    return (
      <H2 fontSize={fontSize} color={color}>
        {children}
      </H2>
    );
  }
  if (h3) {
    return (
      <H3 color={color} style={style}>
        {children}
      </H3>
    );
  }

  if (body1) {
    return <Body1 color={color}>{children}</Body1>;
  }

  if (body1Bold) {
    return <Body1Bold color={color}>{children}</Body1Bold>;
  }

  if (body2Bold) {
    return <Body2Bold color={color}>{children}</Body2Bold>;
  }

  if (body2) {
    return <Body2 color={color}>{children}</Body2>;
  }

  if (body3) {
    return <Body3 color={color}>{children}</Body3>;
  }

  if (commonContainer) {
    return (
      <CommonContainer fontSize={fontSize} color={color}>
        {children}
      </CommonContainer>
    );
  }

  return null;
};
