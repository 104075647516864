import styled from "styled-components";
import { Link } from "react-router-dom";

export const SidebarContainer = styled.aside`
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: #0d0d0d;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease-in-out;
  top: ${({ isSidebarOpen }) => (isSidebarOpen ? 0 : "-100%")};
`;

export const CloseIcon = styled.span`
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
  color: #fff;
`;

export const SidebarMenu = styled.ul`
  list-style: none;
`;

export const SidebarLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  text-decoration: none;
  list-style: none;
  cursor: pointer;
  color: #fff;
  padding: 10px 0;

  &:hover {
    text-decoration: underline;
  }
`;

export const SideBtnWrap = styled.div`
  display: flex;
  margin-top: 40px;
  flex-direction: column;
  justify-content: center;
`;
