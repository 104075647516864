import React from "react";
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableActionIcon,
  ViewDetailDialog,
  Confirm,
} from "../../components";
import { FaEye } from "react-icons/fa";
import useMyMessage from "../../hooks/useMyMessage";
import useMyMessageDelete from "../../hooks/useMyMessageDelete";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { BiChat } from "react-icons/bi";
import { useMutation } from "react-query";
import Alert from "@material-ui/lab/Alert";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";
import MessageThreads from "./messageThreads";

export default () => {
  const [isViewDetailDialog, setIsViewDetailDialog] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState([]);
  const [selectedMessageId, setSelectedMessageId] = React.useState(null);
  const [isConversationOpen, setIsConversationOpen] = React.useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState(null);

  const { isLoading, data, refetch } = useMyMessage();
  const [mutateMyMessageDelete] = useMutation(useMyMessageDelete, {
    onSuccess: (response) => {
      refetch();
      setTimeout(() => {
        setSuccessMessage("");
      }, 3000);
      setSuccessMessage(response.message);
    },
    onError: () => {
      alert("Something went wrong.");
      setIsDeleteOpen(false);
    },
  });

  console.log(123, data);

  if (isLoading) {
    return <Typography body1>Loading...</Typography>;
  }

  const handleViewDetail = ({
    first_name,
    last_name,
    phone_no,
    email,
    message,
  }) => {
    setSelectedUser([
      {
        label: "Name",
        value: `${first_name} ${last_name}`,
      },
      {
        label: "Phone number",
        value: phone_no,
      },
      {
        label: "Email",
        value: email,
      },
      {
        label: "Message",
        value: message,
        newLine: true,
      },
    ]);
    setIsViewDetailDialog(true);
  };

  const handleDelete = () => {
    setIsDeleteOpen(false);
    mutateMyMessageDelete(selectedMessageId);
  };

  return (
    <>
      {isConversationOpen && (
        <MessageThreads
          open={isConversationOpen}
          close={() => setIsConversationOpen(false)}
          messageId={selectedMessageId}
          refetch={refetch}
        />
      )}
      <ViewDetailDialog
        title="User Detail"
        open={isViewDetailDialog}
        close={() => setIsViewDetailDialog(false)}
        data={selectedUser}
      />
      <Confirm
        open={isDeleteOpen}
        close={() => setIsDeleteOpen(false)}
        confirmTitle="Delete"
        handleConfirm={handleDelete}
        title="Delete"
        description="Are you sure you want to delete?"
      />
      {successMessage && <Alert severity="success">{successMessage}</Alert>}
      <Typography h3>My Messages</Typography>
      <Typography body1>Listing of all the messages received.</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell size="small">Date</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Messages</TableCell>
            <TableCell align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length === 0 ? (
            <div style={{ paddingTop: 15, paddingLeft: 8 }}>
              <Typography body1>You do not have any messages.</Typography>
            </div>
          ) : null}
          {data.map(
            (
              { id, first_name, last_name, message, created_at, login_status },
              i
            ) => (
              // email
              <TableRow key={id}>
                <TableCell size="small">
                  {moment(created_at).format("MMM DD, YYYY")}
                </TableCell>
                <TableCell>{`${first_name} ${last_name}`}</TableCell>
                <TableCell>{message}</TableCell>
                <TableCell align="center">
                  {login_status && (
                    <Tooltip title="Threads" arrow>
                      <TableActionIcon
                        size={14}
                        color="#a2d729"
                        onClick={() => {
                          setIsConversationOpen(true);
                          setSelectedMessageId(id);
                        }}
                      >
                        <BiChat />
                      </TableActionIcon>
                    </Tooltip>
                  )}
                  <Tooltip title="Sender Details" arrow>
                    <TableActionIcon
                      size={14}
                      color="#FC7722"
                      onClick={() => handleViewDetail(data[i])}
                    >
                      <FaEye />
                    </TableActionIcon>
                  </Tooltip>
                  <Tooltip title="Delete" arrow>
                    <TableActionIcon
                      size={16}
                      color="#F52222"
                      onClick={() => {
                        setSelectedMessageId(id);
                        setIsDeleteOpen(true);
                      }}
                    >
                      <IoMdCloseCircleOutline />
                    </TableActionIcon>
                  </Tooltip>
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </>
  );
};
