export const footerLinks = [
  {
    id: 1,
    title: "Social Search",
    list: [
      {
        id: 1.1,
        name: "Community Board",
        to: "/communityBoard",
      },
      {
        id: 1.2,
        name: "Events",
        to: "/events",
      },
      {
        id: 1.3,
        name: "News & Articles",
        to: "/news",
      },
      {
        id: 1.4,
        name: "Job Board",
        to: "/jobBoard",
      },
      {
        id: 1.5,
        name: "Resources",
        to: "/resources",
      },
      {
        id: 1.6,
        name: "Associations",
        to: "/associations",
      },
    ],
  },
  {
    id: 2,
    title: "Quick Links",
    list: [
      {
        id: 2.1,
        name: "Home",
        to: "/",
      },
      {
        id: 2.2,
        name: "About Us",
        to: "/aboutUs",
      },
      {
        id: 2.3,
        name: "Commercial",
        to: "/homeSearchList?type=Commercial&searchText=",
        // isReload: true,
      },
      {
        id: 2.4,
        name: "Residential",
        to: "/homeSearchList?type=Residential&searchText=",
        // isReload: true,
      },
      {
        id: 2.5,
        name: "Business Professionals",
        to: "/homeSearchList?type=Business Professionals&searchText=",
        // isReload: true,
      },
    ],
  },
  {
    id: 3,
    title: "Others",
    list: [
      {
        id: 3.1,
        name: "Terms & Conditions",
        target: "_blank",
        to: "/termsAndConditions",
      },
      {
        id: 3.2,
        name: "Disclaimer",
        target: "_blank",
        to: "/disclaimer",
      },
      {
        id: 3.3,
        name: "Privacy Policy",
        target: "_blank",
        to: "/privacyPolicy",
      },
      {
        id: 3.4,
        name: "Cookie Policy",
        target: "_blank",
        to: "/cookiePolicy",
      },
      {
        id: 3.5,
        name: "Frequently Asked Questions(FAQ)",
        to: "/faq",
      },
      // {
      //   id: 3.5,
      //   name: "Help Center",
      //   to: "",
      // },
    ],
  },
];
