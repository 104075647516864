import Api from "../api/api";
import { useQuery } from "react-query";

const getThreads = async (_, messageId) => {
  const { data } = await Api.get(`chats?message_id=${messageId}`);
  return data.data;
};

export default (props) => {
  return useQuery(["threads", props], getThreads);
};
