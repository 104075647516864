import React from "react";
import useResendVificationEmail from "../../hooks/useResendVificationEmail";
import Alert from "@material-ui/lab/Alert";
import { useMutation } from "react-query";

export default ({ userEmail, resetError }) => {
  const [mutateResend, { isLoading, data }] = useMutation(
    useResendVificationEmail
  );

  console.log(data);
  if (isLoading) {
    return (
      <div style={{ marginTop: 10 }}>
        <Alert severity="info">
          Sending you verification email. Please wait.
        </Alert>
      </div>
    );
  }

  const handleResend = () => {
    resetError();
    console.log(userEmail);
    mutateResend({
      email: userEmail,
    });
  };

  return (
    <div style={{ marginTop: 10 }}>
      {data && data.message ? (
        <Alert severity="success">{data.message}</Alert>
      ) : (
        <Alert severity="info">
          If you didn't receive email,&nbsp;
          <span
            style={{
              cursor: "pointer",
              textDecoration: "underline",
              color: "blue",
              fontWeight: 400,
            }}
            onClick={handleResend}
          >
            click here
          </span>
          &nbsp;to resend the verification email.
        </Alert>
      )}
    </div>
  );
};
