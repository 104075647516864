import Api from "../api/api";
import { useQuery } from "react-query";

const getBlogDetail = async (_, blogId) => {
  const { data } = await Api.get(`blog/${blogId}`);
  return data.data.blog;
};

export default (props) => {
  return useQuery(["blog-detail", props], getBlogDetail);
};
