import Api from "../api/api";
import { useQuery } from "react-query";

const getNewsDetail = async (_, newsId) => {
  const { data } = await Api.get(`news/${newsId}`);
  return data.data.news;
};

export default (props) => {
  return useQuery(["news-detail", props], getNewsDetail);
};
