import Api from "../api/api";
import { useQuery } from "react-query";

const getEventDetail = async (_, eventId) => {
  const { data } = await Api.get(`event/${eventId}`);
  return data.data.event;
};

export default (props) => {
  return useQuery(["event-detail", props], getEventDetail);
};
