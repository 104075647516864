import Api from "../api/api";
import { useQuery } from "react-query";

const getMyMessage = async () => {
  const { data } = await Api.get("messages");
  return data.data.messages;
};

export default () => {
  return useQuery("myMessage", getMyMessage);
};
