import styled from "styled-components";

export const BlogImage = styled.div`
  height: 535px;
  background: transparent url(${(props) => props.img}) 0 / cover no-repeat
    padding-box;
  border-radius: 4px;
  opacity: 1;
  margin: 10px 0;

  @media screen and (max-width: 900px) {
    height: 435px;
  }
  @media screen and (max-width: 425px) {
    height: 249px;
  }
`;

export const BlogInfo = styled.div`
  display: inline-flex;
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal normal 14px/27px Poppins;
  letter-spacing: 0px;
  color: #b7b7b7;
  opacity: 1;
  margin-top: 15px;
  margin-bottom: 13px;
  align-items: center;
`;

export const BlogInfoElement = styled.div`
  margin-right: 25px;
  font-size: 0.85rem;
  line-height: 0.85rem;
`;

export const InfoIcon = styled.div`
  /* height: 1.18rem; */
  display: flex;
  margin-right: 8px;
  font-size: 1rem;
`;

export const BlogTitle = styled.div`
  font-weight: medium;
  font-size: 1.2rem;
  color: #494949;
  line-height: 25px;
  margin-bottom: 12px;
`;

export const BlogContent = styled.div`
  color: #494949;
  /* margin-bottom: 80px; */

  & img {
    max-width: 90vw;
  }
`;

export const BlogContainer = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  margin-bottom: 20px;

  @media screen and (max-width: 425px) {
    /* margin: -10px 5px 0px 5px; */
    padding: 0;
    border-radius: 4px;
    opacity: 1;
  }

  ::after {
    content: "";
    display: block;
    margin-bottom: 25px;
  }
`;
export const BlogContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 20px 20px 20px;

  @media screen and (max-width: 1100px) {
    padding: 0 10px 10px 10px;
  }
`;
