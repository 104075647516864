import Api from "../api/api";
import { useQuery } from "react-query";

const getVerifyEmail = async (_, token) => {
  const { data } = await Api.get(`email/verify/${token}`);
  return data;
};

export default (props) => {
  return useQuery(["verifyEmail", props], getVerifyEmail);
};
