import React from "react";
import { Typography } from "../../../components";
import {
  SubscribeTitle,
  SubscribeContainer,
  SubscribeForm,
  SubscribeInputWrap,
  SubscribeInput,
  SubscribeButton,
  SubscribeIcon,
} from "./subscribeElements";
import { FaPaperPlane } from "react-icons/fa";
import useSubscribe from "../../../hooks/useSubscribe";
import { useMutation } from "react-query";
import useForm from "../../../hooks/useForm";
import { ErrorText } from "../../signIn/signInElements";
import validate from "../../../utilities/validate";
import ClipLoader from "react-spinners/ClipLoader";
import Alert from "@material-ui/lab/Alert";

const formData = [
  {
    name: "emailAddress",
    label: "Email Address",
    placeholder: "Enter Your Email Address",
    type: "email",
    value: "",
    required: true,
  },
];
export default () => {
  const { values, handleChange } = useForm(formData);
  const [formErrors, setFormErrors] = React.useState({});
  const [successMessage, setSuccessMessage] = React.useState("");

  const [mutateSubscribe, { isLoading }] = useMutation(useSubscribe, {
    onSuccess: (response) => {
      setSuccessMessage(response.message);
      setTimeout(() => {
        setSuccessMessage("");
      }, 3000);
    },
    onError: () => {
      alert("Something went wrong");
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors({});
    setSuccessMessage("");

    const tempErrors = validate(values);

    if (Object.keys(tempErrors).length) {
      setFormErrors(tempErrors);
    } else {
      mutateSubscribe({
        email: values[0].value,
      });
    }
  };

  return (
    <SubscribeContainer style={{backgroundColor:"#eee",borderBottomLeftRadius:"0",borderBottomRightRadius:"0",borderRadius:0}}>
      <SubscribeTitle style={{paddingTop:"40px"}}>
        <Typography h2>Subscribe To Our Newsletter</Typography>
      </SubscribeTitle>
      <Typography body1>
        Subscribe to our newsletter to get the latest updates and industry insights delivered straight to your inbox!
      </Typography>
      <Typography body1></Typography>
      {successMessage && <Alert severity="success">{successMessage}</Alert>}
      <SubscribeForm onSubmit={handleSubmit} style={{paddingBottom:'24px'}}>
        {values.map(({ placeholder, type, value, name }) => (
          <SubscribeInputWrap key={name}>
            <SubscribeInput
              name={name}
              type={type}
              placeholder={placeholder}
              value={value}
              onChange={handleChange}
              style={{backgroundColor:"white",height:'50px'}}
            />
            {<ErrorText>{formErrors[name]}</ErrorText>}
          </SubscribeInputWrap>
        ))}
        <SubscribeButton disabled={isLoading} style={{height:'50px'}}>
          {isLoading ? (
            <ClipLoader size={28} color="#fff" loading={true} />
          ) : (
            <>
              <SubscribeIcon>
                <FaPaperPlane />
              </SubscribeIcon>
              <Typography h3 color="#fff" style={{height:'50px'}}>
                Subscribe
              </Typography>
            </>
          )}
        </SubscribeButton>
      </SubscribeForm>
    </SubscribeContainer>
  );
};
