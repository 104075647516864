import React from "react";
import { ViewAll, Typography, Image } from "../../../components";
import {
  ListTitleWrapper,
  ListItem,
  ListItemAvatar,
  ListItemColumn3,
  DateWrapper,
} from "./propertyPicksElements";
import useHotProperty from "../../../hooks/useHotProperty";
import moment from "moment";
import { useHistory } from "react-router-dom";

export default () => {
  const { isLoading, data } = useHotProperty();

  const history = useHistory();

  return (
    <>
      <ListTitleWrapper>
        <div />
        <Typography h2>Top CRE Guide Property Highlights.</Typography>
        {data?.property.length !== 0 && (
          <ViewAll onClick={() => history.push("/hotProperty")} />
        )}
      </ListTitleWrapper>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
         
        }}
      >
        {isLoading ? (
          <Typography h3>Loading...</Typography>
        ) : data?.property.length ? (
          data.property
            .slice(0, 2)
            .map(({ id, title, excerpt, date, image }) => (
              <ListItem key={id} style={{ border:"1px solid #ddd"}}>
                <ListItemAvatar>
                  <Image
                    alt=""
                    src={image}
                    style={{ width: 160, height: 110 }}
                  />
                </ListItemAvatar>
                <ListItemColumn3 to={`hotProperty/${id}`}>
                  <Typography h3 height={26}>
                    {title.length > 26
                      ? `${title.substring(0, 27)} . . .`
                      : title}
                  </Typography>
                  <DateWrapper>
                    {/* <CalendarIcon>
                      <MdDateRange />
                    </CalendarIcon> */}
                    <Typography body2>
                      {moment(date).format("MMMM DD, YYYY")}
                    </Typography>
                  </DateWrapper>
                  <div
                    style={{
                      height: 52,
                      overflow: "hidden",
                      color: "#494949",
                      fontWeight: 400,
                      letterSpacing: 0,
                    }}
                  >
                    {excerpt}
                  </div>
                  {/* <Typography body1>{body}</Typography> */}
                </ListItemColumn3>
              </ListItem>
            ))
        ) : (
          <div style={{ padding: "20px 0" }}>
            <Typography body1>No properties listed.</Typography>
          </div>
        )}
      </div>
    </>
  );
};
