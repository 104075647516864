import styled from "styled-components";
import colors from "../../constants/colors";

export const ViewAll = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 0.7rem;
  color: ${(props) => (props.white ? "#fff" : "#494949")};
`;

export const ViewAllIcon = styled.span`
  color: ${(props) => (props.white ? "#fff" : colors.primary)};
  display: flex;
  font-size: 18px;
  margin-left: 4px;
`;
