import React from "react";
import { Typography, Button } from "../../components";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import useResidentialTerritory from "../../hooks/useResidentialTerritory";
import "./residentialTerritory.css";
import useResidentialTerritoryUpdate from "../../hooks/useResidentialTerritoryUpdate";
import { useMutation } from "react-query";

export default ({ categoryId }) => {
  const [residentialTerritory, setResidentialTerritory] = React.useState([]);
  const [stateList, setStateList] = React.useState(null);

  const { data, isLoading, refetch } = useResidentialTerritory();

  const [mutateResidentialTerritory, { isLoading: isLoadingMutate }] =
    useMutation(useResidentialTerritoryUpdate, {
      onSuccess: () => {
        refetch();
      },
      onError: () => alert("Something went wrong"),
    });

  React.useEffect(() => {
    if (data) {
      const tempResidentialTerritory = data.find(
        (d) => d.id === categoryId
      ).residential_territory;

      setResidentialTerritory(tempResidentialTerritory);
      setStateList(tempResidentialTerritory.find((d) => d.selected)?.stateList);
    }
  }, [data, categoryId]);

  if (isLoading) {
    return "Loading..";
  }

  const handleChangeTerritory = (territoryName, list) => {
    setStateList(list);
    setResidentialTerritory(
      residentialTerritory.map((d) => ({
        ...d,
        selected: territoryName === d.name,
      }))
    );
  };

  const handleChangeState = (id) => {
    setStateList(
      stateList.map((state) => ({
        ...state,
        selected: state.id === id ? !state.selected : state.selected,
      }))
    );
  };

  const handleSave = () => {
    mutateResidentialTerritory({
      category_id: categoryId,
      states: stateList
        ? stateList
            .map((state) => (state.selected ? state.id : undefined))
            .filter((d) => d)
        : null,
      territory_type: residentialTerritory.find((d) => d.selected).name,
    });
  };

  return (
    <div style={{ margin: "20px 0" }}>
      <Typography h3>Territory</Typography>
      <div
        style={{
          display: "flex",
          paddingBottom: 20,
          paddingTop: 10,
          flexWrap: "wrap",
        }}
      >
        {residentialTerritory.map(({ name, selected, stateList }) => (
          <div key={name} style={{ paddingRight: 30 }}>
            <Radio
              checked={selected}
              onChange={() => handleChangeTerritory(name, stateList)}
              value={selected}
            />
            <Typography body2 color="#494949">
              {name}
            </Typography>
          </div>
        ))}
      </div>
      {stateList && (
        <div>
          <Typography body2 color="#494949">
            Select up to 28 individual states
          </Typography>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              padding: "10px 0",
              height: 200,
              overflow: "scroll",
              marginBottom: 20,
            }}
          >
            {stateList.map(({ id, code, selected }) => (
              <div
                key={id}
                style={{
                  display: "flex",
                  width: "25%",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  checked={selected}
                  onChange={() => handleChangeState(id)}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
                <Typography body2 color="#494949">
                  {code}
                </Typography>
              </div>
            ))}
          </div>
        </div>
      )}
      <Button onClick={handleSave} isLoading={isLoadingMutate}>
        Save
      </Button>
    </div>
  );
};
