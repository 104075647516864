import React from "react";
import notificationIcon from "../../assets/bell.png";
import { Image } from "../../components";
import { useHistory } from "react-router-dom";
import useNotifications from "../../hooks/useNotifications";

export default () => {
  const history = useHistory();
  const { data, isSuccess } = useNotifications();

  return (
    <div style={{ height: "100%", display: "flex", alignItems: "center" }}>
      <div
        style={{
          width: 40,
          height: 40,
          borderRadius: 25,
          backgroundColor: "#efefef",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginRight: 18,
          position: "relative",
          cursor: "pointer",
        }}
        onClick={() => history.push("/profile/notifications")}
      >
        <Image src={notificationIcon} style={{ width: 19, height: 19 }} />
        {isSuccess && data.notifications.length ? (
          <div
            style={{
              backgroundColor: "red",
              borderRadius: 10,
              position: "absolute",
              width: 20,
              height: 20,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              right: -5,
              top: -5,
            }}
          >
            <span style={{ color: "#fff", fontSize: 11 }}>
              {data.notifications.length}
            </span>
          </div>
        ) : null}
      </div>
    </div>
  );
};
