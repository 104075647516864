import React from "react";

export default (data) => {
  const [values, setValues] = React.useState(data);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setValues(
      values.map((d) => ({
        ...d,
        value: name === d.name ? value : d.value,
      }))
    );
  };

  // const handleKeyDown = (e) => {
  //   const { value } = e.target;

  //   var key = e.keyCode || e.charCode;

  //   let tempValue = value;

  //   if (tempValue.length === 1 && key !== 8) {
  //     tempValue = `(${tempValue}`;
  //   } else if (tempValue.length === 5 && key === 8) {
  //     tempValue = tempValue.substring(0, 4);
  //   } else if (tempValue.length === 5) {
  //     tempValue = `${tempValue.substring(0, 4)}) ${tempValue.charAt(4)}`;
  //   } else if (tempValue.length === 10 && key === 8) {
  //     tempValue = tempValue.substring(0, 9);
  //   } else if (tempValue.length === 10) {
  //     tempValue = `${tempValue.substring(0, 9)}-${tempValue.charAt(9)}`;
  //   }

  //   setValues(
  //     values.map((d) => ({
  //       ...d,
  //       value: d.name === "phoneNumber" ? tempValue.substring(0, 14) : d.value,
  //     }))
  //   );
  // };

  return { values, handleChange, setValues };
};
