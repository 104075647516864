import React from "react";
import { Typography, Button } from "../../components";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Alert from "@material-ui/lab/Alert";
import { useHistory, useParams, useLocation } from "react-router-dom";
import useVerifyEmail from "../../hooks/useVerifyEmail";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography h2>{children}</Typography>
      {/* <Typography body1>Enter below to reset password</Typography> */}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
  },
}))(MuiDialogContent);

export default () => {
  const history = useHistory();

  const { id } = useParams();
  const { search } = useLocation();

  const token = id + search;
  const { isLoading, data, error } = useVerifyEmail(token);

  const handleClick = () => {
    if (data) {
      history.push("/signIn");
    } else {
      history.push("/signUp");
    }
  };

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={true}
      maxWidth={false}
    >
      <DialogTitle id="customized-dialog-title">
        Verifying your account
      </DialogTitle>
      <DialogContent dividers>
        <div style={{ width: 400 }}>
          {isLoading ? (
            <Typography body1>Please wait...</Typography>
          ) : (
            <>
              {data && <Alert severity="success">{data.message}</Alert>}
              {error && (
                <Alert severity="error">{error.response.data.message}</Alert>
              )}
              <Button marginTop={20} onClick={handleClick}>
                {data ? "Sign In" : "Try Again"}
              </Button>
            </>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};
