import React from "react";
import {
  BreadCrumbs,
  Container,
  HeroSection,
  Typography,
} from "../../../components";
import {
  ColumnWrapper,
  LeftColumn,
  ProfileWrap,
  ProfileAvatar,
  ProfileTextWrap,
  RightColumn,
  FavIcon,
  InfoRowWrap,
  InfoIcon,
  VisitWebsiteBtn,
  WebsiteIcon,
  DetailWrap,
} from "./profileDetailElements";
import { IoIosCall, IoIosGlobe } from "react-icons/io";
import { useHistory } from "react-router-dom";
import { MdMessage, MdContactPhone } from "react-icons/md";
import defaultProfilePicture from "../../../assets/defaultProfilePicture.png";
import ContactDialog from "../contactDialog";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import { useMutation } from "react-query";
import useMakeFavorite from "../../../hooks/useMakeFavorite";
import useProfileDetail from "../../../hooks/useProfileDetail";
import { AppContext } from "../../../AppContext";
import Tooltip from "@material-ui/core/Tooltip";
import queryString from "query-string";

export default () => {
  const { id: profileId, type } = queryString.parse(location.search, {
    parseNumbers: true,
  });

  const subCategoryName =
    location.search.split("&name=")[1] &&
    location.search.split("&name=")[1].replaceAll("%20", " ");

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const { currentUser } = React.useContext(AppContext);
  const history = useHistory();

  const { data, error, refetch, isSuccess } = useProfileDetail(profileId);

  const [mutateFavorite] = useMutation(useMakeFavorite, {
    onSuccess: () => {
      refetch();
    },
    onError: () => {
      alert("Something went wrong during make favorite.");
    },
  });

  React.useEffect(() => {
    if (isSuccess && data === null) {
      alert("User not found.");
      history.push("/homeSearchList?type=Commercial&searchText=");
    }
  }, [isSuccess, data]);

  if (error) {
    alert("Something went wrong during profile detail fetch.");
    return null;
  }

  const list = [
    {
      name: "Call Now",
      icon: <IoIosCall />,
      color: "#21CCB5",
      className: "profile-detail call",
    },
    {
      name: "Send SMS",
      icon: <MdMessage />,
      color: "#4297EB",
      className: "profile-detail sms",
    },
    {
      name: "Contact",
      icon: <MdContactPhone />,
      color: "#EBAC42",
      className: "profile-detail contact",
    },
  ];

  const handleFavorite = (favorited, id) => {
    mutateFavorite({
      user_id: id,
      favorited: !favorited,
    });
  };

  const href = (name, phone_no) => {
    if (name === "Call Now") {
      return `tel:${phone_no}`;
    } else if (name === "Send SMS") {
      return `sms:${phone_no}`;
    }
    return null;
  };

  const profileItem = ({ title, data_type, filter_value }) => {
    return (
      <div key={title} style={{ padding: "5px 0" }}>
        <Typography body1Bold>{title}</Typography>
        &nbsp;{":"}&nbsp;
        {data_type === "single_value" && (
          <Typography body3>{filter_value}</Typography>
        )}
        {data_type === "multi_select" && (
          <Typography body3>
            {Array.isArray(filter_value)
              ? filter_value.join(", ")
              : filter_value}
          </Typography>
        )}
      </div>
    );
  };

  const {
    profile: {
      id,
      first_name,
      last_name,
      address,
      phone_no,
      email,
      image,
      description,
      url,
      favorited,
      company,
      city,
      job_title,
      state,
      zip_code,
    },
    commercial_profile,
    business_profile,
    residential_profile,
    commercial_territory,
    business_territory,
    residential_territory,
  } = data || { profile: {} };

  const profileList = [
    {
      label: "Commercial",
      value: commercial_profile,
      value2: commercial_territory,
    },
    {
      label: "Residential",
      value: residential_profile,
      value2: residential_territory,
    },
    {
      label: "Business Professionals",
      value: business_profile,
      value2: business_territory,
    },
  ].filter((d) => d.value);

  return (
    <>
      <ContactDialog
        id={profileId}
        open={isDialogOpen}
        close={() => setIsDialogOpen(false)}
      />
      <HeroSection
        title={type}
        description={subCategoryName ? subCategoryName : ""}
      />
      {/* <HeroSection title={type} description={address} description2={company} /> */}
      <Container>
        <BreadCrumbs
          data={[
            {
              title: type,
              pathname: `/homeSearchList?type=${type}&searchText=`,
            },
            {
              title: subCategoryName,
              pathname:
                subCategoryName &&
                `/subCategoryList?type=${type}&name=${subCategoryName}`,
            },
            {
              title: `${first_name} ${last_name}`,
            },
          ]}
        />
        <ColumnWrapper>
          <LeftColumn>
            <ProfileWrap>
              <ProfileAvatar src={image ? image : defaultProfilePicture} />
              <ProfileTextWrap>
                <Typography h3>
                  {first_name} {last_name}
                </Typography>
                <Typography body2>{job_title}</Typography>
                <Typography body2>{company}</Typography>
              </ProfileTextWrap>
              {!currentUser && (
                <Tooltip title="Add to favorite" placement="top" arrow>
                  <FavIcon onClick={() => history.push("/signIn")}>
                    <FaRegHeart />
                  </FavIcon>
                </Tooltip>
              )}
              {data && currentUser && currentUser.id !== id && (
                <Tooltip
                  title={favorited ? "Remove from favorite" : "Add to favorite"}
                  placement="top"
                  arrow
                >
                  <FavIcon onClick={() => handleFavorite(favorited, id)}>
                    {favorited ? <FaHeart /> : <FaRegHeart />}
                  </FavIcon>
                </Tooltip>
              )}
            </ProfileWrap>
            <InfoRowWrap>
              {list.map(({ name, icon, color, className }) => (
                <a
                  key={name}
                  href={href(name, phone_no)}
                  onClick={() => {
                    if (name === "Contact") setIsDialogOpen(true);
                  }}
                  className={className}
                >
                  <InfoIcon color={color}>{icon}</InfoIcon>
                  <Typography body1>{name}</Typography>
                </a>
              ))}
            </InfoRowWrap>
            {url && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 15,
                }}
              >
                <VisitWebsiteBtn
                  onClick={() => {
                    if (url.indexOf("http") > -1) {
                      window.open(url);
                    } else {
                      window.open(`http://${url}`);
                    }
                  }}
                >
                  <WebsiteIcon>
                    <IoIosGlobe />
                  </WebsiteIcon>
                  <Typography body2 color="#fff">
                    Visit Website
                  </Typography>
                </VisitWebsiteBtn>
              </div>
            )}
            <div style={{ marginTop: 20 }}>
              {[
                { label: "Email", value: email },
                { label: "Phone", value: phone_no },
                // { label: "City", value: city },
                {
                  label: "Address",
                  value: [address, city, state, zip_code].join(", "),
                },
                // { label: "State", value: state },
                // { label: "Zip Code", value: zip_code },
              ].map(
                ({ label, value }) =>
                  value && (
                    <div key={label} style={{ paddingBottom: 10 }}>
                      <Typography body1Bold>{label}: </Typography>
                      <Typography body1>{value}</Typography>
                    </div>
                  )
              )}
            </div>
          </LeftColumn>
          <RightColumn>
            <DetailWrap>
              <p style={{ fontSize: 22, fontWeight: 500, color: "#494949" }}>
                Details
              </p>
              <div
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
            </DetailWrap>
            <DetailWrap>
              <p
                style={{
                  fontSize: 22,
                  fontWeight: 500,
                  color: "#494949",
                  marginBottom: 10,
                }}
              >
                Business Profile
              </p>
              {profileList.map(({ label, value, value2 }) => (
                <div key={label} style={{ marginBottom: 15 }}>
                  <p
                    style={{ fontSize: 20, fontWeight: 500, color: "#494949" }}
                  >
                    {label}
                  </p>
                  {value2 && (
                    <ul>
                      <li style={{ marginLeft: 6 }}>
                        Teritorry:{" "}
                        <span style={{ color: "#494949", fontSize: 14 }}>
                          {value2.map((d) => d).join(", ")}
                        </span>
                      </li>
                    </ul>
                  )}
                  {Object.keys(value).map((key) => (
                    <ul key={key} style={{ marginTop: 10 }}>
                      <li style={{ marginLeft: 6 }}>{key}</li>
                      {/* <Typography h3>{key}</Typography> */}
                      <div style={{ marginLeft: 12 }}>
                        {value[key].map((d) => profileItem(d))}
                      </div>
                    </ul>
                  ))}
                </div>
              ))}
            </DetailWrap>
          </RightColumn>
        </ColumnWrapper>
      </Container>
    </>
  );
};
