import React from "react";
import Typography from "../Typography/Typography";
import moment from "moment";
import {
  CommentWrap,
  CommentProfileWrap,
  CommentUserNameWrap,
  CommentUserPicture,
  DeleteComment,
  EditComment,
  CommentTextArea,
  ReplyWrap,
} from "./LoanCommentsElements";
import { MdDeleteForever, MdModeEdit } from "react-icons/md";
import { AppContext } from "../../AppContext";
import Confirm from "../Confirm/Confirm";
import useDeleteComment from "../../hooks/useDeleteComment";
import useEditComment from "../../hooks/useEditComment";
import { useMutation } from "react-query";
import Button from "../Button/Button";
import defaultProfilePicture from "../../assets/defaultProfilePicture.png";
import ReplyDialog from "./ReplyDialog";
import usePostComment from "../../hooks/usePostComment";

export default ({ commentList, loanId, refetchLoanDetail }) => {
  const { currentUser } = React.useContext(AppContext);
  const [isEditCommentOpen, setIsEditCommentOpen] = React.useState(false);
  const [isDeleteCommentOpen, setIsDeleteCommentOpen] = React.useState(false);
  const [selectedComment, setSelectedComment] = React.useState({});
  const [selectedReply, setSelectedReply] = React.useState({});
  const [isReplyOpen, setIsReplyOpen] = React.useState(false);
  const [replyMessage, setReplyMessage] = React.useState("");
  const [isEditReplyOpen, setIsEditReplyOpen] = React.useState(false);
  const [isDeleteReplyOpen, setIsDeleteReplyOpen] = React.useState(false);

  const [mutateDeleteComment] = useMutation(useDeleteComment, {
    onSuccess: () => refetchLoanDetail(),
  });

  const [mutateEditComment, { isLoading: isLoadingEditComment }] = useMutation(
    useEditComment,
    {
      onSuccess: () => {
        setIsEditCommentOpen(false);
        setIsEditReplyOpen(false);
        refetchLoanDetail();
      },
    }
  );

  const [mutatePostComment, { isLoading: isLoadingPostComment }] = useMutation(
    usePostComment,
    {
      onSuccess: () => {
        refetchLoanDetail();
        setReplyMessage("");
        setIsReplyOpen(false);
        refetchLoanDetail();
      },
    }
  );

  const editComment = () => {
    mutateEditComment({
      commentId: isEditCommentOpen ? selectedComment.id : selectedReply.id,
      body: {
        subject: "",
        comment: isEditCommentOpen
          ? selectedComment.comment
          : selectedReply.comment,
      },
    });
  };

  const deleteComment = () => {
    setIsDeleteCommentOpen(false);
    setIsDeleteReplyOpen(false);
    mutateDeleteComment({
      commentId: isDeleteCommentOpen ? selectedComment.id : selectedReply.id,
      body: {
        loan_post_id: loanId,
      },
    });
  };

  const handleReply = () => {
    mutatePostComment({
      parent_comment_id: selectedComment.id,
      loan_post_id: loanId,
      comment: replyMessage,
    });
  };

  return (
    <div>
      <Confirm
        open={isDeleteCommentOpen || isDeleteReplyOpen}
        close={() => setIsDeleteCommentOpen(false)}
        confirmTitle="Delete"
        handleConfirm={deleteComment}
        title="Delete Comment"
        description="Are you sure you want to delete comment?"
      />
      <ReplyDialog
        title="Reply"
        open={isReplyOpen}
        close={() => setIsReplyOpen(false)}
      >
        <div style={{ width: 500 }}>
          <CommentTextArea
            rows={6}
            value={replyMessage}
            placeholder="Enter your reply"
            onChange={(e) => setReplyMessage(e.target.value)}
          />
          <Button onClick={handleReply} isLoading={isLoadingPostComment}>
            Submit
          </Button>
        </div>
      </ReplyDialog>
      {commentList.map(({ id, comment, created_at, user, replies }, index) => (
        <div>
          <CommentWrap key={id}>
            <CommentProfileWrap>
              <div style={{ display: "flex", flex: 1 }}>
                <div style={{ display: "flex", flex: 1 }}>
                  <CommentUserPicture
                    src={user?.image ? user?.image : defaultProfilePicture}
                  />
                  <CommentUserNameWrap>
                    <Typography body1Bold>{user?.name}</Typography>
                    <Typography body2>
                      {moment(created_at).format("MMM DD, YYYY")}
                    </Typography>
                  </CommentUserNameWrap>
                </div>
                <div style={{ display: "flex" }}>
                  <ReplyWrap
                    onClick={() => {
                      setSelectedComment(commentList[index]);
                      setIsReplyOpen(true);
                    }}
                  >
                    Reply
                  </ReplyWrap>
                  {currentUser && currentUser.id === user?.id && (
                    <div
                      style={{
                        display: "flex",
                        color: "#999",
                        marginLeft: 10,
                        marginTop: 6,
                      }}
                    >
                      <EditComment
                        onClick={() => {
                          setSelectedComment(commentList[index]);
                          setIsEditCommentOpen(!isEditCommentOpen);
                        }}
                      >
                        <MdModeEdit size={20} />
                      </EditComment>
                      <DeleteComment
                        onClick={() => {
                          setSelectedComment(commentList[index]);
                          setIsDeleteCommentOpen(true);
                        }}
                      >
                        <MdDeleteForever size={20} />
                      </DeleteComment>
                    </div>
                  )}
                </div>
              </div>
            </CommentProfileWrap>
            {isEditCommentOpen && selectedComment.id === id ? (
              <>
                <CommentTextArea
                  rows={6}
                  value={selectedComment.comment}
                  placeholder="Comment"
                  onChange={(e) =>
                    setSelectedComment({
                      ...commentList[index],
                      comment: e.target.value,
                    })
                  }
                />
                <Button
                  // color="#40A747"
                  onClick={editComment}
                  isLoading={isLoadingEditComment}
                >
                  Submit
                </Button>
              </>
            ) : (
              <Typography body1>{comment}</Typography>
            )}
          </CommentWrap>
          {replies?.length
            ? replies.map((d, replyIndex) => (
                <div
                  key={d.id}
                  style={{
                    marginLeft: 100,
                    marginBottom: 20,
                    backgroundColor: "#f9f9f9",
                    padding: "12px 16px",
                  }}
                >
                  <div style={{ display: "flex", flex: 1 }}>
                    <div style={{ flex: 1 }}>
                      <CommentUserPicture
                        src={
                          d.user?.image ? d.user?.image : defaultProfilePicture
                        }
                      />
                      <CommentUserNameWrap>
                        <Typography body1Bold>{d.name}</Typography>
                        <Typography body2>
                          {moment(d.created_at).format("MMM DD, YYYY")}
                        </Typography>
                      </CommentUserNameWrap>
                    </div>
                    {currentUser && currentUser.id === d.user?.id && (
                      <div
                        style={{
                          display: "flex",
                          color: "#999",
                          marginLeft: 10,
                          marginTop: 6,
                        }}
                      >
                        <EditComment
                          onClick={() => {
                            setSelectedReply(replies[replyIndex]);
                            setIsEditReplyOpen(!isEditReplyOpen);
                          }}
                        >
                          <MdModeEdit size={20} />
                        </EditComment>
                        <DeleteComment
                          onClick={() => {
                            setSelectedReply(replies[replyIndex]);
                            setIsDeleteReplyOpen(true);
                          }}
                        >
                          <MdDeleteForever size={20} />
                        </DeleteComment>
                      </div>
                    )}
                  </div>
                  <div style={{ marginTop: 15 }}>
                    {isEditReplyOpen && selectedReply.id === d.id ? (
                      <>
                        <CommentTextArea
                          rows={6}
                          value={selectedReply.comment}
                          placeholder="Reply"
                          onChange={(e) =>
                            setSelectedReply({
                              ...replies[replyIndex],
                              comment: e.target.value,
                            })
                          }
                        />
                        <Button
                          // color="#40A747"
                          onClick={editComment}
                          isLoading={isLoadingEditComment}
                        >
                          Submit
                        </Button>
                      </>
                    ) : (
                      <Typography body1>{d.comment}</Typography>
                    )}
                  </div>
                </div>
              ))
            : null}
        </div>
      ))}
    </div>
  );
};
