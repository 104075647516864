import Api from "../api/api";

export default async (props) => {
  const { data } = await Api.post(
    `loan-post-listing?page=${props.currentPage}`,
    {
      filters: props.filters,
    }
  );
  return data.data;
};
