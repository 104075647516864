import styled from "styled-components";
import { MAX_WIDTH } from "../../constants/layout";

export const TopBar = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 0;
  border-bottom: 0.5px solid #efefef;
`;

export const TopBarContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: ${MAX_WIDTH};
  justify-content: flex-end;
  padding-left: 8px;

  a {
    color: #9a9999;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.21px;
    text-decoration: none;

    :hover {
      text-decoration: underline;
    }
  }
`;
