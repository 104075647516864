import Api from "../api/api";
import { useQuery } from "react-query";

const getMyLoanPost = async (_, page) => {
  const { data } = await Api.get(`my-loan-posts?page=${page}`);
  return data.data;
};

export default (props) => {
  return useQuery(["myLoanPost", props], getMyLoanPost);
};
