export default (amount) => {
  let value = Number(amount);

  if (value && typeof value === "number") {
    value = value.toLocaleString();
  } else {
    value = "";
  }
  return value;
};
