import Api from "../api/api";
import { useQuery } from "react-query";

const getSiteSettings = async () => {
  const { data } = await Api.get("site-settings");
  return data.data;
};

export default () => {
  return useQuery("siteSettings", getSiteSettings);
};
