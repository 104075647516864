import React from "react";
import { Typography, Container } from "../../components";
import { Tag } from "../listing/listingElements";
import { useHistory } from "react-router-dom";

export default () => {
  const history = useHistory();

  return (
    <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "140px 0",
        }}
      >
        <h1 style={{ fontSize: 50 }}>Oops !</h1>
        <div style={{ paddingTop: 20 }}>
          <Typography h2>404 - PAGE NOT FOUND</Typography>
        </div>
        <div style={{ paddingTop: 20 }}>
          <div style={{ textAlign: "center" }}>
            <Typography body1>
              The page you are looking for might have been removed or had its's
              name
            </Typography>
          </div>
          <div style={{ textAlign: "center" }}>
            <Typography body1>
              changed or is temporarily unavailable.
            </Typography>
          </div>
        </div>
        <div style={{ paddingTop: 20 }}>
          <Tag
            selected
            onClick={() => {
              history.push({
                pathname: "/",
              });
            }}
          >
            <Typography body2 color="#fff">
              GO TO HOMEPAGE
            </Typography>
          </Tag>
        </div>
      </div>
    </Container>
  );
};
