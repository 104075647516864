import styled from "styled-components";

export const Table = styled.div`
  margin-top: 15px;
  min-width: 450px;
  background: white;
`;

export const TableHead = styled.div`
  font-weight: 500;
  color: #494949;
  font-size: 16px;
  background-color: #f2f2f2;
`;

export const TableRow = styled.div`
  display: flex;
  border-bottom: 0.5px solid #f1f1f1;
`;

export const TableCell = styled.div`
  display: flex;
  flex: ${({ size }) => (size === "small" ? 1 : 2)};
  text-align: ${({ align }) => (align ? align : "left")};
  justify-content: ${({ align }) => (align ? align : "left")};
  padding: 12px 10px;
  flex-wrap: wrap;
`;

export const TableBody = styled.div`
  font-weight: 400;
  color: #494949;
  font-size: 16px;
  background-color: #fff;
`;

export const TableActionIcon = styled.span`
  width: 30px;
  height: 30px;
  background-color: ${({ color }) => color};
  border-radius: 15px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: ${({ size }) => (size ? size : 12) + "px"};
`;
