import React from "react";
import {
  Typography,
  Button,
  FormLabel,
  FormInput,
  // FormTextArea,
  FormErrorText,
} from "../../../components";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { IoMdAdd } from "react-icons/io";
import IconButton from "@material-ui/core/IconButton";
import { MdClose } from "react-icons/md";
import ReactSelect from "react-select";
import useLoanPostAddForm from "../../../hooks/useLoanPostAddForm";
import useLoanPostAdd from "../../../hooks/useLoanPostAdd";
import { useMutation } from "react-query";
import getAmount from "../../../utilities/getAmount";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography h2>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <MdClose />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
  },
}))(MuiDialogContent);

export default ({ refetch }) => {
  const [open, setOpen] = React.useState(false);
  const [formData, setFormData] = React.useState([]);
  // const [successMessage, setSuccessMessage] = React.useState("");
  const [formErrors, setFormErrors] = React.useState({});
  const dialogContentRef = React.useRef(null);
  const [previewImage, setPreviewImage] = React.useState(null);
  const imageRef = React.useRef(null);

  const { isLoading: isLoadingLoanPostAddForm, data: loanPostAddFormData } =
    useLoanPostAddForm();
  const [mutateLoanPostAdd, { isLoading: isLoadingLoanPostAdd }] = useMutation(
    useLoanPostAdd,
    {
      onSuccess: () => {
        refetch();
        // setSuccessMessage(response.message);
        // dialogContentRef.current.scrollTo(0, 0);
        setFormData(loanPostAddFormData);
        handleClose();
      },
      onError: () => {
        alert("Something went wrong");
      },
    }
  );

  React.useEffect(() => {
    if (loanPostAddFormData) {
      setFormData(loanPostAddFormData);
    }
  }, [loanPostAddFormData]);

  const handleClose = () => {
    // setSuccessMessage("");
    setFormErrors({});
    setOpen(false);
    setPreviewImage(null);
  };

  const handleOpen = () => setOpen(true);

  const handleChange = (selectedItem, filter_name) => {
    setFormData(
      formData.map((d) => ({
        ...d,
        current_value:
          d.filter_name === filter_name ? selectedItem : d.current_value,
      }))
    );
  };

  // const handleChangeDescription = (e) => {
  //   setFormData(
  //     formData.map((d) => ({
  //       ...d,
  //       current_value:
  //         d.filter_name === "description" ? e.target.value : d.current_value,
  //     }))
  //   );
  // };

  const handleSubmit = () => {
    // setSuccessMessage("");
    setFormErrors({});

    const validate = () => {
      let error = {};

      formData.forEach(({ filter_title, filter_name, current_value }) => {
        if (
          (filter_name === "title" && !current_value) ||
          (filter_name === "description" && !current_value)
        ) {
          error[filter_name] = `${filter_title} is required`;
        } else if (
          (filter_name === "property_value" &&
            current_value &&
            isNaN(current_value)) ||
          (filter_name === "loan_amount" &&
            current_value &&
            isNaN(current_value))
        ) {
          error[filter_name] = `${filter_title} should be a valid number`;
        }
      });

      return error;
    };
    const tempErrors = validate();

    if (Object.keys(tempErrors).length > 0) {
      setFormErrors(tempErrors);
    } else {
      mutateLoanPostAdd({
        image: previewImage,
        filters: formData,
      });
    }
  };

  const filterItem = ({
    filter_type,
    filter_title,
    filter_list,
    current_value,
    filter_name,
  }) => {
    if (
      (filter_type === "input" || filter_type === "amount_input") &&
      filter_name !== "description"
    ) {
      return (
        <div key={filter_name} style={{ width: "48%", paddingBottom: 10 }}>
          <FormLabel required={filter_name === "title"}>
            {filter_title}
          </FormLabel>
          <FormInput
            dollarSign={filter_type === "amount_input"}
            value={
              filter_type === "amount_input"
                ? getAmount(current_value)
                : current_value
            }
            onChange={(e) => {
              let value = e.target.value;
              // remove comma
              if (filter_type === "amount_input") {
                value = value.replace(/,/g, "");
              }
              handleChange(value, filter_name);
            }}
          />
          {filter_name === "title" && (
            <FormErrorText>{formErrors.title}</FormErrorText>
          )}
          {filter_name === "property_value" && (
            <FormErrorText>{formErrors.property_value}</FormErrorText>
          )}
          {filter_name === "loan_amount" && (
            <FormErrorText>{formErrors.loan_amount}</FormErrorText>
          )}
        </div>
      );
    } else if (filter_type === "select" || filter_type === "multi_select") {
      return (
        <div key={filter_name} style={{ width: "48%", paddingBottom: 10 }}>
          <FormLabel>{filter_title}</FormLabel>
          <ReactSelect
            options={filter_list}
            value={current_value}
            onChange={(selectedItem) => handleChange(selectedItem, filter_name)}
            isClearable
            isMulti={filter_type === "multi_select"}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  const handleChangeImage = (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let selectedFile = e.target.files[0];

    reader.onloadend = () => {
      setPreviewImage(reader.result);
      imageRef.current.value = "";
    };
    reader.readAsDataURL(selectedFile);
  };

  const description = formData.find((d) => d.filter_name === "description");

  return (
    <>
      <Button icon={<IoMdAdd />} onClick={handleOpen}>
        Create Community Board
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth={false}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Add To Community Board
        </DialogTitle>
        <DialogContent dividers ref={dialogContentRef}>
          {/* {successMessage && <Alert severity="success">{successMessage}</Alert>} */}
          {isLoadingLoanPostAddForm ? (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: 800,
              }}
            >
              <Typography h3>Loading...</Typography>
            </div>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  width: 800,
                  justifyContent: "space-between",
                }}
              >
                {formData.map((filter) => filterItem(filter))}
              </div>
              <div>
                <FormLabel required>Description</FormLabel>
                {/* <FormTextArea
                  rows={6}
                  value={
                    description && description.current_value !== null
                      ? description.current_value
                      : ""
                  }
                  onChange={handleChangeDescription}
                /> */}
                <CKEditor
                  editor={ClassicEditor}
                  data={
                    description && description.current_value !== null
                      ? description.current_value
                      : ""
                  }
                  config={{
                    toolbar: [
                      "heading",
                      "|",
                      "bold",
                      "italic",
                      "link",
                      "undo",
                      "redo",
                      "numberedList",
                      "bulletedList",
                    ],
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setFormData(
                      formData.map((d) => ({
                        ...d,
                        current_value:
                          d.filter_name === "description"
                            ? data
                            : d.current_value,
                      }))
                    );
                  }}
                />
                <FormErrorText>{formErrors.description}</FormErrorText>
              </div>
              <div style={{ marginTop: 8 }}>
                <FormLabel>Image</FormLabel>
                <div>
                  <input
                    type="file"
                    onChange={handleChangeImage}
                    ref={imageRef}
                  />
                </div>
                {previewImage && (
                  <div>
                    <img
                      style={{ height: 100, objectFit: "cover", marginTop: 8 }}
                      src={previewImage}
                    />
                    <button
                      style={{
                        marginTop: 6,
                        padding: "0 6px",
                        display: "block",
                      }}
                      onClick={() => {
                        setPreviewImage(null);
                      }}
                    >
                      Remove
                    </button>
                  </div>
                )}
              </div>
              <Button
                marginTop={20}
                onClick={handleSubmit}
                isLoading={isLoadingLoanPostAdd}
              >
                Submit
              </Button>
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
