import styled from "styled-components";
import { sm } from "../../../constants/layout";
import { Link } from "react-router-dom";

export const ListTitleWrapper = styled.div`
  display: flex;
  padding: 15px 0;
  justify-content: space-between;
`;

export const ListItem = styled.div`
  width: 49%;
  background-color: #fff;
  padding: 16px;
  margin-bottom: 20px;
  border-radius: 4px;
  display: flex;

  @media screen and (max-width: ${sm}) {
    width: 100%;
  }
`;

export const ListItemColumn3 = styled(Link)`
  flex: 2;
  text-decoration: none;
  cursor: pointer;
`;

export const ListItemAvatar = styled.div`
  flex: 1;
  padding-right: 10px;
`;

export const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 0;
`;
