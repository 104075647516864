import styled from "styled-components";
import { FaAngleRight } from "react-icons/fa";

export const BreadCrumbsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  padding: 20px 0px;
`;

export const BreadCrumbItem = styled.a`
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;

  :hover {
    text-decoration: underline;
  }
`;

export const ArrowIcon = styled(FaAngleRight)`
  color: #494949;
  margin: 0 2px;
`;
