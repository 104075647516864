  //const { REACT_APP_ENV } = process.env;
  const { REACT_APP_ENV } = 'production'

export const SHORT_DESCRIPTION_LENGTH = 300;

let API_URL;
// API_URL = "https://admin.bighound.com/api/";
API_URL = "https://bighoundadmin.gtechvision.com/api/";
//API_URL = "http://127.0.0.1:8000/api/";

// if (REACT_APP_ENV === "staging") {
//   //API_URL = "https://bighound.themenepal.info/api/";
//   API_URL = "http://127.0.0.1:8000/api/";
// } else if (REACT_APP_ENV === "production") {
//   //API_URL = "https://admin.bighound.com/api/";
//   API_URL = "http://127.0.0.1:8000/api/";
// }

export { API_URL };
