import Api from "../api/api";
import { useQuery } from "react-query";

const getStateList = async () => {
  const { data } = await Api.get("states");
  return data.data.states;
};

export default () => {
  return useQuery("stateList", getStateList);
};
