import React from "react";
import { Typography, Button } from "../index";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import { MdClose } from "react-icons/md";
import { useMutation } from "react-query";
import useSendReport from "../../hooks/useSendReport";
import Alert from "@material-ui/lab/Alert";
import ReCAPTCHA from "react-google-recaptcha";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography h2>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <MdClose />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
  },
}))(MuiDialogContent);

export default ({ open, close, id }) => {
  const [reportMessage, setReportMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const [isRecaptcha, setIsRecaptcha] = React.useState(false);

  const [mutateSendReport, { isLoading }] = useMutation(useSendReport, {
    onSuccess: () => {
      setReportMessage("");
      setSuccessMessage("Report has been sent.");
    },
  });

  const handleClose = () => {
    close();
    setSuccessMessage("");
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth={false}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        Report
      </DialogTitle>
      <DialogContent dividers>
        <div style={{ width: 500 }}>
          {successMessage && <Alert severity="success">{successMessage}</Alert>}
          <Typography body1>
            Report this content for possible violations
          </Typography>
          {/* <textarea
            rows={6}
            value={reportMessage}
            placeholder="Enter here"
            onChange={(e) => setReportMessage(e.target.value)}
            style={{
              width: "100%",
              border: "1px solid #efefef",
              borderRadius: 4,
              padding: 20,
              color: "#494949",
              marginTop: 10,
            }}
          /> */}
          <div style={{ margin: "16px 0" }}>
            <ReCAPTCHA
              sitekey="6LclH4sbAAAAAAsIGTGNY_i3yyHox4zYZg0RBeg3"
              onChange={(token) => {
                if (token) {
                  setIsRecaptcha(true);
                }
              }}
            />
          </div>
          <Button
            onClick={() => {
              if (isRecaptcha) {
                mutateSendReport({
                  id,
                  message: reportMessage,
                });
              }
            }}
            isLoading={isLoading}
          >
            Submit
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
