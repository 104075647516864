import styled from "styled-components";
import colors from "../../constants/colors";

export const Tabs = styled.div`
  margin-top: 30px;
  display: flex;
`;

export const Tab = styled.div`
  border-bottom: ${({ selected }) =>
    selected ? `2px solid ${colors.primary}` : "#494949"};
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 400;
  padding-bottom: 5px;
  margin-right: 15px;
`;
