import React from "react";
import {
  HeroSection,
  BreadCrumbs,
  Container,
  Typography,
} from "../../../components";
import useHotPropertyDetail from "../../../hooks/useHotPropertyDetail";
// import useTopNews from "../../../hooks/useTopNews";
// import profileImage from "../../assets/profileImage.png";
import {
  NewsImage,
  NewsInfo,
  NewsInfoElement,
  InfoIcon,
  NewsContent,
  NewsContainer,
  NewsContentContainer,
  MainNewsContainer,
  // SideContainer,
  // SideContent,
  // SideItem,
  // TopNewsInfo,
  // SideItemContainer,
} from "./hotPropertyDetailElements";
import { useParams } from "react-router-dom";
import moment from "moment";
import { MdDateRange } from "react-icons/md";
import { FaUserCheck } from "react-icons/fa";
import ReactHtmlParser from "react-html-parser";

export default () => {
  const { hotPropertyId } = useParams();
  const {
    isLoading: isLoadingHotPropertyDetail,
    data: hotPropertyDetailData,
  } = useHotPropertyDetail(hotPropertyId);
  // const { isLoading: isLoadingHotProperty, data: hotPropertyData } = useTopNews();

  return (
    <>
      <HeroSection
        title={hotPropertyDetailData?.title}
        description={hotPropertyDetailData?.sub_title}
      />
      <Container>
        <BreadCrumbs
          data={[
            { title: "Big Hound Property Picks", pathname: "/hotProperty" },
            { title: hotPropertyDetailData?.title },
          ]}
        />
        {isLoadingHotPropertyDetail ? (
          <Typography h3>Loading...</Typography>
        ) : (
          <NewsContainer>
            <MainNewsContainer>
              <NewsImage img={hotPropertyDetailData?.image} />
              <NewsContentContainer>
                <Typography h3>{hotPropertyDetailData?.title}</Typography>
                <NewsInfo>
                  <InfoIcon>
                    <FaUserCheck />
                  </InfoIcon>
                  <NewsInfoElement>
                    {hotPropertyDetailData.author}
                  </NewsInfoElement>
                  <InfoIcon>
                    <MdDateRange />
                  </InfoIcon>
                  <NewsInfoElement>
                    {hotPropertyDetailData &&
                      moment(hotPropertyDetailData.date).format(
                        "MMMM DD, YYYY"
                      )}
                  </NewsInfoElement>
                </NewsInfo>
                <Typography h3>{hotPropertyDetailData?.name}</Typography>
                <NewsContent>
                  <Typography body1>
                    {hotPropertyDetailData &&
                      ReactHtmlParser(hotPropertyDetailData.content)}
                  </Typography>
                </NewsContent>
              </NewsContentContainer>
            </MainNewsContainer>
            {/* <SideContainer>
              <SideContent>
                <Typography h2 fontSize="22px">
                  Top News and Articles
                </Typography>
                {isLoadingHotProperty ? (
                  <Typography h3>Loading...</Typography>
                ) : (
                  <SideItemContainer>
                    {hotPropertyData.map(({ id, title, excerpt, date }) => (
                      <SideItem key={id}>
                        <Typography h3>
                          <Link to={`/news/${id}`}>{title}</Link>
                        </Typography>
                        <Typography body1>{excerpt}</Typography>
                        <TopNewsInfo>
                          <InfoIcon>
                            <MdDateRange />
                          </InfoIcon>
                          <NewsInfoElement>
                            {moment(date).format("DD MMMM, YYYY")}
                          </NewsInfoElement>
                        </TopNewsInfo>
                      </SideItem>
                    ))}
                  </SideItemContainer>
                )}
              </SideContent>
            </SideContainer> */}
          </NewsContainer>
        )}
      </Container>
    </>
  );
};
