import React from "react";
import {
  HeroSection,
  BreadCrumbs,
  Container,
  Typography,
  Image,
  LoadMore,
} from "../../components";
import useNews from "../../hooks/useNews";
// import profileImage from "../../assets/profileImage.png";
import {
  NewsWrap,
  NewsItem,
  NewsInfo,
  NewsInfoItem,
  NewsInfoIcon,
  NewsSubTitle,
  NewsContainer,
} from "./newsElements";
import { BsFillPersonCheckFill } from "react-icons/bs";
import { Link } from "react-router-dom";

export default () => {
  const [newsList, setNewsList] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);

  const { isLoading, data, isSuccess } = useNews(currentPage);

  React.useEffect(() => {
    if (data) {
      if (currentPage === 1) {
        setNewsList(data.news);
      } else {
        setNewsList([...newsList, ...data.news]);
      }
    }
  }, [data]);

  return (
    <>
      <HeroSection
        title="News & Articles"
        description="Here is what's trending now."
      />
      <Container>
        <BreadCrumbs data={[{ title: "News & Articles" }]} />
        {isLoading && newsList.length === 0 ? (
          <Typography h3>Loading...</Typography>
        ) : (
          <NewsWrap>
            {newsList.map(({ id, title, image, excerpt, author }) => (
              <NewsItem key={id}>
                <Link to={`/news/${id}`}>
                  <Image
                    src={image}
                    style={{
                      width: "100%",
                      height: 250,
                      objectFit: "cover",
                    }}
                  />

                  <NewsContainer>
                    <NewsInfo>
                      <NewsInfoItem>
                        <NewsInfoIcon>
                          <BsFillPersonCheckFill />
                        </NewsInfoIcon>
                        <Typography body2>{author}</Typography>
                      </NewsInfoItem>
                    </NewsInfo>
                    <Typography h3>{title}</Typography>
                    <NewsSubTitle>
                      {excerpt && <Typography body1>{excerpt}</Typography>}
                    </NewsSubTitle>
                  </NewsContainer>
                </Link>
              </NewsItem>
            ))}
          </NewsWrap>
        )}
        <LoadMore
          dataPerPage={data}
          allData={newsList}
          isLoading={isLoading}
          isSuccess={isSuccess}
          onClick={() => setCurrentPage(currentPage + 1)}
        />
      </Container>
    </>
  );
};
