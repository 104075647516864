import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "../Button/Button";

export default ({
  open,
  close,
  confirmTitle,
  handleConfirm,
  title,
  description,
}) => {
  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <div style={{ paddingRight: 200 }}>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        </div>
      </DialogContent>
      <DialogActions>
        <div style={{ paddingBottom: 10 }}>
          <Button onClick={close} cancel>
            Cancel
          </Button>
        </div>
        <div style={{ paddingRight: 10, paddingBottom: 10 }}>
          <Button onClick={handleConfirm}>{confirmTitle}</Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};
