import React from "react";
import { Typography } from "../../../components";
import { CategoryBtnWrap, CategoryBtn } from "../profileElements";
import { MdCheckCircle } from "react-icons/md";
import colors from "../../../constants/colors";
import "./editor.css";

export default ({
  categories,
  selectedCategory,
  setSelectedCategory,
  setSubCategories,
}) => {
  const _statusColor = (status) => {
    if (status === "pending") {
      return "#cccccc";
    } else if (status === "approved") {
      return "#28a745";
    } else {
      return null;
    }
  };

  return (
    <div style={{ marginTop: 30 }}>
      <Typography body1Bold>Join As A</Typography>
      <CategoryBtnWrap>
        {categories.map(({ id, title, subcategories, approval_status }) => (
          <CategoryBtn
            key={id}
            selected={selectedCategory === id}
            onClick={() => {
              setSelectedCategory(id);
              setSubCategories(subcategories);
            }}
          >
            {_statusColor(approval_status) && (
              <span
                style={{
                  fontSize: 22,
                  color: _statusColor(approval_status),
                  position: "absolute",
                  top: -8,
                  right: -10,
                  backgroundColor: "#fff",
                  width: 25,
                  height: 25,
                  borderRadius: 15,
                }}
              >
                <MdCheckCircle />
              </span>
            )}
            <Typography
              body2
              color={selectedCategory === id ? "#fff" : colors.primary}
            >
              {title}
            </Typography>
          </CategoryBtn>
        ))}
      </CategoryBtnWrap>
    </div>
  );
};
