import Api from "../api/api";
import { useQuery } from "react-query";

const getLoanDetail = async (id) => {
  const { data } = await Api.get(`loan-post/${id}`);
  return data.data.loan_post;
};

export default (props) => {
  return useQuery(["loanDetail", props], () => getLoanDetail(props));
};
