import React from "react";
import { Container, ContainerContent } from "./ContainerElements";

export default ({ children }) => {
  return (
    <Container>
      <ContainerContent>{children}</ContainerContent>
    </Container>
  );
};
