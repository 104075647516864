import Api from "../api/api";
import { useQuery } from "react-query";

const getBlogs = async (_, page) => {
  const { data } = await Api.get(`blogs?page=${page}`);
  return data.data;
};

export default (props) => {
  return useQuery(["blogs", props], getBlogs);
};
