import React from "react";
import Button from "../Button/Button";

export default ({ dataPerPage, allData, isLoading, onClick, isSuccess }) => {
  return (
    <div
      style={{ display: "flex", justifyContent: "center", margin: "20px 0" }}
    >
      {isLoading && allData.length > 0 && <Button isLoading={isLoading} />}
      {isSuccess &&
        dataPerPage.pagination.current_page <
          dataPerPage.pagination.total_pages && (
          <Button onClick={onClick}>Load More</Button>
        )}
    </div>
  );
};
