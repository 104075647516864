import Api from "../api/api";
import { useQuery } from "react-query";

const getRisedentialTerritory = async () => {
  const { data } = await Api.get("profile/business-states");
  return data.data.categories;
};

export default () => {
  return useQuery("residentialTerritory", getRisedentialTerritory);
};
