import Api from "../api/api";
import { useQuery } from "react-query";

const getMyFavorite = async (page) => {
  const { data } = await Api.get(`my-favorites?page=${page}`);
  return data.data;
};

export default (page) => {
  return useQuery(["myFavorite", page], () => getMyFavorite(page));
};
