import Api from "../api/api";
import { useQuery } from "react-query";

const getNews = async (_, page) => {
  const { data } = await Api.get(`news?page=${page}`);
  return data.data;
};

export default (props) => {
  return useQuery(["news", props], getNews);
};
