import React from "react";
import colors from "../../constants/colors";
import Typography from "../Typography/Typography";
import {
  BreadCrumbsContainer,
  BreadCrumbItem,
  ArrowIcon,
} from "./BreadCrumbsElements";
import { useHistory } from "react-router-dom";

export default ({ data }) => {
  const history = useHistory();

  const Item = ({ title, pathname }) => {
    if (pathname) {
      return (
        <BreadCrumbItem onClick={() => history.push(pathname)}>
          <ArrowIcon />
          <Typography body1>{title}</Typography>
        </BreadCrumbItem>
      );
    } else if (title) {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <ArrowIcon />
          <Typography body1 color={colors.primary}>
            {title}
          </Typography>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <BreadCrumbsContainer>
      <BreadCrumbItem onClick={() => history.push("/")}>
        <Typography body1>Home</Typography>
      </BreadCrumbItem>
      {data.map(({ title, pathname }, index) => (
        <Item key={title ? title : index} title={title} pathname={pathname} />
      ))}
    </BreadCrumbsContainer>
  );
};
