import React from "react";
import { Typography } from "..";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import { MdClose } from "react-icons/md";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography h3>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <MdClose />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
  },
}))(MuiDialogContent);

export default ({ title, children, open, close }) => {
  return (
    <Dialog
      onClose={close}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth={false}
    >
      <DialogTitle id="customized-dialog-title" onClose={close}>
        {title}
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
    </Dialog>
  );
};
