import React from "react";
import Typography from "../Typography/Typography";
import {
  HeroSection,
  HeroSectionContainer,
  DescriptionWrap,
} from "./HeroSectionElements";

export default ({ title, description, description2 }) => {
  return (
    <HeroSection>
      <HeroSectionContainer>
        <Typography h1>{title}</Typography>
        <DescriptionWrap>
          <Typography body1 color="#fff">
            {description}
          </Typography>
        </DescriptionWrap>
        <DescriptionWrap>
          <Typography body1 color="#fff">
            {description2}
          </Typography>
        </DescriptionWrap>
      </HeroSectionContainer>
    </HeroSection>
  );
};
