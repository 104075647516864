import Api from "../api/api";
import { useQuery } from "react-query";

const getLoanPostAddForm = async () => {
  const { data } = await Api.get("loan-post-add");
  return data.data.filters;
};

export default () => {
  return useQuery("loanPostAddForm", getLoanPostAddForm);
};
