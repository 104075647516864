import styled from "styled-components";

export const AssociationsWrap = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 20px 0;

  ::after {
    content: "";
    margin-bottom: 15px;
  }
`;

export const AssociationsContainer = styled.div`
  padding: 15px 20px;
  display: grid;
  grid-auto-flow: column;
  padding: 25px;
  grid-template-columns: min-content auto;
  grid-gap: 25px;

  @media screen and (max-width: 1110px) {
    grid-template-columns: unset;
    justify-content: center;
    grid-template-rows: auto auto;
  }
`;

export const AssociationAvatar = styled.img`
  width: 100%;
  height: 195px;
  width: 195px;
  object-fit: cover;
`;

export const AssociationsSubTitle = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-auto-rows: min-content;

  a {
    text-decoration: none;
  }

  @media screen and (max-width: 1110px) {
    justify-items: center;
  }
`;

export const AssociationsItem = styled.div`
  background: #fff;
  border-radius: 4px;
`;

export const AssociationBtn = styled.div`
  display: inline-flex;
  width: fit-content;
  padding: 8px 14px;
  background: #fc7722;
  border-radius: 24px;
  align-items: center;
  opacity: 1;
  text-align: left;
  letter-spacing: 0px;
  font-size: 13px;
  color: #ffffff;

  :hover {
    transition: all 0.2s ease-in-out;
    opacity: 0.8;
  }
`;
export const AssociationLickIcon = styled.div`
  line-height: 22px;
  font-size: 21px;
  margin-right: 5px;
`;
