import React from "react";
import {
  HeroSection,
  BreadCrumbs,
  Container,
  Typography,
  Image,
  LoadMore,
} from "../../components";
import useEvents from "../../hooks/useEvents";
import {
  NewsWrap,
  NewsItem,
  NewsInfo,
  NewsInfoIcon,
  NewsSubTitle,
  NewsContainer,
} from "../news/newsElements";
import { Link } from "react-router-dom";
import moment from "moment";
import { MdDateRange } from "react-icons/md";

export default () => {
  const [eventList, setEventList] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);

  const { isLoading, data, isSuccess } = useEvents(currentPage);

  React.useEffect(() => {
    if (data) {
      if (currentPage === 1) {
        setEventList(data.events);
      } else {
        setEventList([...eventList, ...data.news]);
      }
    }
  }, [data]);

  return (
    <>
      <HeroSection title="Events" description="" />
      <Container>
        <BreadCrumbs data={[{ title: "Events" }]} />
        {isLoading && eventList.length === 0 ? (
          <Typography h3>Loading...</Typography>
        ) : (
          <NewsWrap>
            {eventList.map(
              ({ id, title, image, excerpt, start_date, end_date }) => (
                <NewsItem key={id}>
                  <Link to={`/events/${id}`}>
                    <Image
                      src={image}
                      style={{
                        width: "100%",
                        height: 250,
                        objectFit: "cover",
                      }}
                    />
                    <NewsContainer>
                      <NewsInfo>
                        <NewsInfoIcon>
                          <MdDateRange />
                        </NewsInfoIcon>
                        <Typography body2>
                          {moment(start_date).format("MMM DD, YYYY")}
                          {" - "}
                          {moment(end_date).format("MMM DD, YYYY")}
                        </Typography>
                      </NewsInfo>
                      <Typography h3 marginTop={5}>
                        {title}
                      </Typography>
                      <NewsSubTitle>
                        {excerpt && <Typography body1>{excerpt}</Typography>}
                      </NewsSubTitle>
                    </NewsContainer>
                  </Link>
                </NewsItem>
              )
            )}
          </NewsWrap>
        )}
        <LoadMore
          dataPerPage={data}
          allData={eventList}
          isLoading={isLoading}
          isSuccess={isSuccess}
          onClick={() => setCurrentPage(currentPage + 1)}
        />
      </Container>
    </>
  );
};
