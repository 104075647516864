import React, { useState, useEffect } from "react";
import {
  Button,
  FormErrorText,
  FormInput,
  FormLabel,
  FormTextArea,
  Typography,
} from "../../components";
import { SettingsBtn } from "./profileElements";
import Dialog from "@material-ui/core/Dialog";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { MdClose } from "react-icons/md";
import IconButton from "@material-ui/core/IconButton";
import useForm from "../../hooks/useForm";
import validate from "../../utilities/validate";
import useChangePassword from "../../hooks/useChangePassword";
import { useMutation } from "react-query";
import Alert from "@material-ui/lab/Alert";
import useUserSettings from "../../hooks/useUserSettings";
import useDeleteAccountRequest from "../../hooks/useDeleteAccountRequest";

const formData = [
  {
    name: "oldPassword",
    label: "Old Password",
    placeholder: "",
    type: "password",
    value: "",
    required: true,
  },
  {
    name: "password",
    label: "New Password",
    placeholder: "",
    type: "password",
    value: "",
    required: true,
  },
  {
    name: "confirmPassword",
    label: "Confirm Password",
    placeholder: "",
    type: "password",
    value: "",
    required: true,
  },
];

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography h2>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <MdClose />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
  },
}))(MuiDialogContent);

export default () => {
  const [selectedSetting, setSelectedSetting] = useState(null);
  const { values, handleChange, setValues } = useForm(formData);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [formErrors, setFormErrors] = React.useState({});
  const [successMessage, setSuccessMessage] = React.useState("");

  const [deleteAccOptionList, setDeleteAccOptionList] = useState([]);

  const [isOtherReasonSelected, setIsOtherReasonSelected] = useState(false);
  const [otherReason, setOtherReason] = useState("");

  const [mutateChangePassword, { isLoading: isLoadingChangePassword }] =
    useMutation(useChangePassword, {
      onSuccess: () => {
        // setSuccessMessage(response.data.message);
        setSuccessMessage("Password Changed Successfully.");
        resetFormState();
        setFormErrors({});
      },
      onError: (e) => {
        const error = e.response.data.data;
        const errorValues = Object.values(error)[0];
        setErrorMessage(errorValues[0]);
      },
    });

  const [mutateDeleteAccountRequest, { isLoading: isLoadingDeleteRequest }] =
    useMutation(useDeleteAccountRequest, {
      onSuccess: () => {
        refetch();
        handleCloseModal();
        // setSuccessMessage("Account deletion request sent successfully.");
      },
      onError: (e) => {
        const error = e.response.data;
        setErrorMessage(error.message);
      },
    });

  const { data: userSettings, isLoading, refetch } = useUserSettings();

  useEffect(() => {
    if (userSettings) {
      setDeleteAccOptionList(
        userSettings["account-delete-opiton"].map((d) => ({
          ...d,
          selected: false,
        }))
      );
    }
  }, [userSettings]);

  const resetFormState = () => {
    setValues(
      values.map((d) => ({
        ...d,
        value: "",
      }))
    );
  };

  const handleSubmit = () => {
    setFormErrors({});

    setSuccessMessage("");
    setErrorMessage("");

    if (selectedSetting === "changePassword") {
      const tempErrors = validate(values);

      if (Object.keys(tempErrors).length > 0) {
        setFormErrors(tempErrors);
      } else {
        const old_password = values.find((d) => d.name === "oldPassword").value;
        const password = values.find((d) => d.name === "password").value;
        const password_confirmation = values.find(
          (d) => d.name === "confirmPassword"
        ).value;

        mutateChangePassword({
          old_password,
          password,
          password_confirmation,
        });
      }
    } else {
      mutateDeleteAccountRequest({
        account_delete_option_id: otherReason
          ? otherReason
          : deleteAccOptionList.find((d) => d.selected)?.id,
      });
    }
  };

  const handleCloseModal = () => {
    setSelectedSetting(null);
    setErrorMessage("");
    setSuccessMessage("");
    setValues(
      values.map((d) => ({
        ...d,
        value: "",
      }))
    );
    setDeleteAccOptionList(
      deleteAccOptionList.map((d) => ({
        ...d,
        selected: false,
      }))
    );
  };

  if (isLoading) {
    return <Typography h3>Loading...</Typography>;
  }

  const accountDeletionPending = userSettings["account-delete-request-status"];

  const checkDisabled = () => {
    if (deleteAccOptionList?.find((d) => d.selected)) {
      return false;
    } else if (otherReason) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div>
      <Dialog
        onClose={handleCloseModal}
        aria-labelledby="customized-dialog-title"
        open={!!selectedSetting}
        maxWidth={false}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleCloseModal}>
          {selectedSetting === "changePassword"
            ? "Change password"
            : "Delete account"}
        </DialogTitle>
        {successMessage && <Alert severity="success">{successMessage}</Alert>}
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        <DialogContent dividers>
          <div style={{ width: 400 }}>
            {selectedSetting === "changePassword" ? (
              <div>
                {values.map(
                  ({ name, label, placeholder, type, value, required }) => (
                    <div key={name}>
                      <FormLabel required={required}>{label}</FormLabel>
                      <FormInput
                        name={name}
                        onChange={handleChange}
                        placeholder={placeholder}
                        value={value}
                        type={type}
                      />
                      {<FormErrorText>{formErrors[name]}</FormErrorText>}
                    </div>
                  )
                )}
                <Button
                  marginTop={20}
                  onClick={handleSubmit}
                  isLoading={isLoadingChangePassword}
                  // disabled={!deleteAccOptionList?.find((d) => d.selected)}
                >
                  Change Password
                </Button>
              </div>
            ) : (
              <div>
                <Typography body1>
                  Please provide a reason why you are deleting your account.
                </Typography>
                <div style={{ marginTop: 20 }}>
                  {deleteAccOptionList?.map(({ id, title, selected }) => (
                    <div key={id}>
                      <input
                        type="radio"
                        id="option"
                        value={title}
                        checked={selected}
                        style={{ cursor: "pointer" }}
                        onChange={() => {
                          setOtherReason("");
                          setIsOtherReasonSelected(false);
                          setDeleteAccOptionList(
                            deleteAccOptionList.map((d) => ({
                              ...d,
                              selected: d.id === id,
                            }))
                          );
                        }}
                        readOnly
                      />
                      <label htmlFor="option" style={{ marginLeft: 10 }}>
                        {title}
                      </label>
                    </div>
                  ))}
                  <div>
                    <input
                      style={{ cursor: "pointer" }}
                      type="radio"
                      id="option"
                      value={otherReason}
                      checked={isOtherReasonSelected}
                      onChange={() => {
                        setIsOtherReasonSelected(true);
                        setDeleteAccOptionList(
                          deleteAccOptionList.map((d) => ({
                            ...d,
                            selected: false,
                          }))
                        );
                      }}
                      readOnly
                    />
                    <label htmlFor="option" style={{ marginLeft: 10 }}>
                      Other reason
                    </label>
                  </div>
                </div>
                {isOtherReasonSelected && (
                  <FormTextArea
                    rows={4}
                    value={otherReason}
                    onChange={(e) => setOtherReason(e.target.value)}
                  />
                )}
                <Button
                  marginTop={20}
                  onClick={handleSubmit}
                  isLoading={isLoadingDeleteRequest}
                  disabled={checkDisabled()}
                >
                  Request for Deletion
                </Button>
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
      <Typography h3>Settings</Typography>
      {accountDeletionPending && (
        <div
          style={{
            backgroundColor: "#cce5ff",
            borderColor: "#b8daff",
            padding: "10px 14px",
            borderRadius: 2,
            marginTop: 15,
          }}
        >
          <Typography body1>
            Request for account deletion has been sent. Your account will be
            deleted once admin approve it.
          </Typography>
        </div>
      )}
      <div style={{ marginTop: 20, display: "flex" }}>
        <SettingsBtn onClick={() => setSelectedSetting("changePassword")}>
          Change Password
        </SettingsBtn>
        {!accountDeletionPending && (
          <SettingsBtn onClick={() => setSelectedSetting("deleteAccount")} red>
            Delete Account
          </SettingsBtn>
        )}
      </div>
    </div>
  );
};
