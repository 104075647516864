import React from "react";
import {
  HeroSection,
  BreadCrumbs,
  Container,
  Typography,
} from "../../components";
import ReactHtmlParser from "react-html-parser";
import usePageContent from "../../hooks/usePageContent";

export default () => {
  const { isLoading, data } = usePageContent("faq");

  return (
    <>
      <HeroSection title="Frequently Asked Questions" description="" />
      <Container>
        <BreadCrumbs data={[{ title: "Frequently Asked Questions" }]} />
        {isLoading ? (
          <Typography h3>Loading...</Typography>
        ) : (
          <Typography commonContainer>
            {ReactHtmlParser(data.content)}
          </Typography>
        )}
      </Container>
    </>
  );
};
