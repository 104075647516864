import Api from "../api/api";
import { useQuery } from "react-query";

const getProfileDetail = async (_, profileId) => {
  const { data } = await Api.get(`profile-detail/${profileId}`);
  return data.data;
};

export default (props) => {
  return useQuery(["profileDetail", props], getProfileDetail, {
    enabled: !!props,
  });
};
