import Api from "../api/api";
import { useQuery } from "react-query";

const getLoanPostEditForm = async (_, id) => {
  const { data } = await Api.get(`loan-post-edit/${id}`);
  return data.data;
};

export default (id) => {
  return useQuery(["loanPostEditForm", id], getLoanPostEditForm);
};
