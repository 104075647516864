import { API_URL } from "../constants/constants";
import axios from "axios";
import getToken from "./getToken";

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.clear();
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

export default {
  get: async (resource, params, headers) => {
    return await axios({
      method: "get",
      url: API_URL + resource,
      headers: headers
        ? headers
        : {
            Authorization: `Bearer ${await getToken()}`,
          },
      params,
    });
  },
  post: async (resource, data, headers) => {
    return await axios({
      method: "post",
      url: API_URL + resource,
      headers: headers
        ? headers
        : {
            Authorization: `Bearer ${await getToken()}`,
          },
      data,
    });
  },
  delete: async (resource) => {
    return await axios({
      method: "delete",
      url: API_URL + resource,
      headers: {
        Authorization: `Bearer ${await getToken()}`,
      },
    });
  },
  put: async (resource, data) => {
    return await axios({
      method: "put",
      url: API_URL + resource,
      headers: {
        Authorization: `Bearer ${await getToken()}`,
      },
      data,
    });
  },
};
